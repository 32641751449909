import hackforsoc from './Assets/hack4soc.gif';
import digilogo from './Assets/sponsorsLogos/digilogo.png';
import devfolio from './Assets/sponsorsLogos/devfolio.png';
import filecoin from './Assets/sponsorsLogos/filecoin.png';
import polygon from './Assets/sponsorsLogos/polygon.png';
import alan from './Assets/sponsorsLogos/alan.png';
import koii from './Assets/sponsorsLogos/koii.png';
// import celo from "./Assets/sponsorsLogos/celo.png";
import echo3d from './Assets/sponsorsLogos/echo3d.png';
import streamyard from './Assets/sponsorsLogos/streamyard1.JPG';
import wolfram from './Assets/sponsorsLogos/wolfram.jpg';
import gfg from './Assets/sponsorsLogos/gfg.png';
import certopus from './Assets/sponsorsLogos/certopus.png';
import replit from './Assets/sponsorsLogos/replit.png';
import taskade from './Assets/sponsorsLogos/taskade.png';
import interviecake from './Assets/sponsorsLogos/ic.png';
import interviewbuddy from './Assets/sponsorsLogos/ib.png';
import ieeeBangloreSection from './Assets/sponsorsLogos/IEEEbangloreSection.jpg';
import ieeeBangaloreCompSoc from './Assets/sponsorsLogos/IEEE-CS-BangaloreChapter-Logo-1.png';
import hack2Skill from './Assets/sponsorsLogos/Hack2Skill.jpg';
import digitalocean from './Assets/sponsorsLogos/do.png';
import postman from './Assets/sponsorsLogos/pm.svg';
import axure from './Assets/sponsorsLogos/axure.png';
import echo3D from './Assets/sponsorsLogos/echo3D.jpeg';
import chegu from './Assets/sponsorsLogos/chegu.png';
import bindu from './Assets/sponsorsLogos/bindu.png';

//Organisation team
//import ashok from './Assets/teami/ashok_sir.jpg';
import Malavika from './Assets/teami/Malavika_HariPrasad.jpg';
import Nisarga from './Assets/teami/Nisarga.jpg';
import prajwal from './Assets/teami/prajwal.jpg';
import samhitha from './Assets/teami/samhitha.jpg';
import Shubha from './Assets/teami/ShubhaPrada.jpeg';
import Sonia from './Assets/teami/Sonia_Singh.jpeg';
import neha from './Assets/teami/neha.jpeg';
import naveen from './Assets/teami/naveen.jpg';
import sannidhi from './Assets/teami/sannidhi.jpg';
import mayur from './Assets/teami/mayur.jpg';
import vivek from './Assets/teami/vivek.jpg';
import vaibhavi from './Assets/teami/vaibhavi.jpeg';
import shashank from './Assets/teami/shashank.jpeg';
import jahnavi from './Assets/teami/jahnavi.jpg';
import Apoorva from './Assets/teami/Apoorva.jpg';
import Anushka from './Assets/teami/anushka.jpg';
import adarsh from './Assets/teami/adarsh.jpeg';
import prajwalPawar from './Assets/teami/PrajwalM.jpg';
import sneha from './Assets/teami/sneha.jpg';
import jayanth from './Assets/teami/jayanth.jpg';
import joshua from './Assets/teami/joshua.jpg';
import likhith from './Assets/teami/likhith.jpg';
import chirag from './Assets/teami/chiragjs.png';
import sundarakrishnan from './Assets/teami/sundarakrishnan.png';
import ayush from './Assets/teami/ayushRatan.png';
import tejas from './Assets/teami/tejasPattar.png';

const TOP_SECTION = {
  TITLE: 'Hack4Soc 2.0',
  //!TODO uncomment the TypedEffect component in the Top Division and add the text you want to show
  Typed_effect: [
    '27 August - 28 August',
    '24 hours of Offline creation',
    'Win awesome prizes',
    'Offline fun events'
  ],
  SHORT_DESCRIPTION: 'Where technology meets humanity...',
  KNOW_MORE: 'Stay tuned for more',
  IMG_SRC: hackforsoc,
  DISCORD_LINK: 'https://discord.gg/cvHPEK7U3v',
  JUDGES_FORM_LINK: '#Jugdes Form Link',
  HACKERS_REGISTRATION_FORM_LINK: '#Hackers Registration devfolio link'
};

const SOCIALS = {
  instagram: 'https://www.instagram.com/ieee_rvce/',
  discord: 'https://discord.gg/4q5SsRzc',
  linkedin: 'https://www.linkedin.com/company/rv-ieee/',
  email: 'mailto:ieeervce@rvce.edu.in',
  mail: 'ieeervce@rvce.edu.in'
};

const MIDDLE_SECTION = {
  TITLE: 'What is Hack4Soc 2.0?',
  LONG_DESCRIPTION:
    'Hack4soc 2.0 is an inter-college hackathon organized by IEEE RVCE Computer Society. Teams from colleges across the country will be invited to build solutions addressing various problem statements with a humanitarian theme , aiming to claim the prize.',
  LOGO_EFFECT: true,
  LOGO: ''
};

const HACKATHON_TRACKS = {
  title: 'Our Hackathon Tracks',
  tracks: [
    {
      title: 'Generative AI 4 Soc',
      description: 'Empower positive change with AI. From personalized healthcare to inclusive education, explore how AI can transform society for the better.'
    },
    {
      title: 'Fintech 4 Soc',
      description: 'hape a better world with Fintech. Drive financial inclusion, support micro-entrepreneurs, and enable sustainable investments to make a lasting impact on communities'
    },
    {
      title: ' Blockchain 4 Soc',
      description: 'Unleash the potential of Blockchain for societal good. Revolutionize supply chains, enhance identity security, and create transparent solutions for humanitarian aid.'
    }
  ],
  images: []
};

const FOOTER = {
  VOLUNTEERING_FORM: {
    required: true,
    src: '#Volunteer form link'
  },
  JOIN_TEAM: {
    required: true,
    src: '#Join team link'
  },
  Privacy_policy: {
    required: true,
    src: ''
  },
  Terms_of_use: {
    required: true,
    src: ''
  }
};

const calenderStartingDate = {
  month: 8,
  year: 2022
};

const NGOSection = [
  {
    imgSrc:
      'https://dreampathfoundation.com/wp-content/uploads/2021/10/1454309431-1.png',
    imgAlt: 'DreamPath Foundation',
    imgDescription:
      'DreamPath Foundation is a non-profit organisation operating from Bengaluru. Our work is centred around career awareness and guidance. Our workshops and interventions are designed to fulfill the needs of high-schoolers and adolescents. We are a team of experienced professionals from varied backgrounds who facilitate the workshops and training programs. Since our inception in 2013, we have addressed over 20,000 students, conducted several career awareness workshops and are guiding many children one-on-one in career exploration and decision-making. We have a special focus on underprivileged and marginalized youth, as well as children with learning challenges. We offer a whole range of child and adolescent friendly services in the field of career selection, mentoring, aptitude assessment, study skills and life skills development. ​DreamPath also undertakes teacher training programs in the area of career guidance.',
    key: '1'
  },
  {
    imgSrc: 'https://humanityorg.in/wp-content/uploads/2021/05/humanity.png',
    imgAlt: 'HUManity',
    imgDescription:
      'They are committed to support the children living in CCIs through their efficient voluntary model and channelizing various collaborations to create a sustainable community.',
    key: '2'
  }
  // {
  //   imgSrc:
  //     'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80',
  //   imgAlt: 'NGO 1',
  //   imgDescription:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam volutpat fermentum felis. Integer scelerisque sapien elit, ut rhoncus enim pretium nec. Nam dolor elit, ullamcorper id eleifend at, rutrum nec turpis. Nunc nunc dolor, rhoncus nec sagittis quis, imperdiet id mi. Etiam mattis est felis. Aliquam erat volutpat. Nunc ultrices odio et enim aliquam, vel congue nibh eleifend. Nullam et libero quis sem ultrices gravida semper vel libero. Nunc varius turpis felis, eget tempus ligula feugiat eu. Mauris consequat vulputate turpis, nec cursus felis ultricies a.',
  //   key: '3'
  // }
];

const schedule = [
  {
    day: '16-7-2022',
    events: [
      {
        title: 'Opening Ceremoney',
        timings: '8 AM - 9 AM',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '16-7-2022',
    events: [
      {
        title: 'Event',
        timings: '11 AM - 12 PM',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '16-7-2022',
    events: [
      {
        title: 'Event',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '17-7-2022',
    events: [
      {
        title: 'Event',
        timings: '12 AM - 1 Pm',
        link: 'https://google.com'
      }
    ]
  },
  {
    day: '17-7-2022',
    events: [
      {
        title: 'Closing Ceremoney',
        timings: '6 PM - 7 PM',
        link: 'https://google.com'
      }
    ]
  }
];

const Prizeinfo = [
  [
    //Array 1
    {
      icon: <i className="first fas fa-4x fa-trophy"></i>,
      type: 'Overall First',
      content:
        'The first overall prize will be given to a project that outstands all other submissions.'
    },
    {
      icon: <i className=" second fas fa-4x fa-medal"></i>,
      type: 'Overall Second',
      content:
        'The second overall prize will be given to the second-best project in the hackathon.'
    },
    {
      icon: <i className=" third fas fa-4x fa-skiing-nordic"></i>,
      type: 'Overall Third',
      content:
        'The third overall prize will be given to the third-best project in the hackathon.'
    }
  ],
  [
    //Array 2
    {
      icon: <i className="first fas fa-4x fa-trophy"></i>,
      type: ' Best Women Team',
      content:
        'Your project will qualify for this category if your all team members are women.'
    },
    {
      icon: <i className="first fas fa-4x fa-trophy"></i>,
      type: 'Best DDU Team',
      content:
        'Your project will qualify for this category if your all team members are from DDU.'
    },
    {
      icon: <i className="fas fa-user-friends seventh fa-3x "></i>,
      type: 'More prizes',
      content: 'More prizes will be revealed later'
    }
  ],
  [
    //Array 3
  ]
];

const TeamInfo = [
  [
    {
      Name: 'Prajwal M Pawar',
      role: "Chair, Computer Society '23",
      //github: 'https://github.com/R-Ayush777',
      // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
      img: prajwalPawar
    },
    {
      Name: 'Likhith',
      role: "Vice Chair, Computer Society '23",
      //github: 'https://github.com/R-Ayush777',
      // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
      img: likhith
    },
    {
      Name: 'Shubhaprada K P',
      role: "Chair, Computer Society '22",
      //github: 'https://github.com/R-Ayush777',
      // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
      img: Shubha
    },
    {
      Name: 'Prajwal P',
      role: "Vice Chair, Computer Society '22",
      //github: 'https://github.com/Smit-05',
      //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
      img: prajwal
    },
    {
      Name: 'Joshua Elias Alva',
      role: "Treasurer, Computer Society '23",
      //github: '#',
      //linkedin: 'https://www.linkedin.com/in/nirali-darji-1b766a209',
      img: joshua
    },

    {
      Name: 'Sonia Singh B',
      role: "Treasurer, Computer Society '22",
      //github: 'https://github.com/R-Ayush777',
      // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
      img: Sonia
    },
    {
      Name: 'Jayanth C',
      role: "Joint Secretary, Computer Society '23",
      //github: 'https://github.com/R-Ayush777',
      // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
      img: jayanth
    },
    {
      Name: 'Sneha P M',
      role: "Joint Secretary, Computer Society '23",
      //github: 'https://github.com/R-Ayush777',
      // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
      img: sneha
    }
  ],
  // [
  //   // Array 3

  //   {
  //     Name: 'Shashank Dhavala',
  //     role: 'Main Execom',
  //     //github: 'https://github.com/vasu-1',
  //     //linkedin: 'https://www.linkedin.com/in/vashishth-patel-312a52204/',
  //     img: shashank
  //   },
  //   {
  //     Name: 'Anushka Subhramanian',
  //     role: 'Main Execom',
  //     //github: 'https://github.com/Smit-05',
  //     //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
  //     img: Anushka
  //   },
  //   {
  //     Name: 'Jahnavi Urs',
  //     role: 'Main Execom',
  //     //github: 'https://github.com/AneriSonani09',
  //     //linkedin: 'https://www.linkedin.com/in/aneri-sonani-061b44204/',
  //     img: jahnavi
  //   },
  //   {
  //     Name: 'Vivek',
  //     role: 'Main Execom',
  //     //github: 'https://github.com/R-Ayush777',
  //     // linkedin: 'https://www.linkedin.com/in/ayush-rudani-7a3516204/',
  //     img: vivek
  //   }
  // ],
  [
    {
      Name: 'Chirag kr Jaiswal ',
      role: 'Web team',
      //github: 'https://github.com/Smit-05',
      //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
      img: chirag
    },
    {
      Name: 'Sundarakrishnan N',
      role: 'Web team',
      //github: 'https://github.com/Smit-05',
      //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
      img: sundarakrishnan
    },
    {
      Name: 'Ayush Ratan',
      role: 'Web team',
      //github: 'https://github.com/Smit-05',
      //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
      img: ayush
    },
    {
      Name: 'Tejas Pattar',
      role: 'Web team',
      //github: 'https://github.com/Smit-05',
      //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
      img: tejas
    }
  ]
  // [
  //   {
  //     Name: 'Apoorva',
  //     role: 'Design Team Head',
  //     //github: 'https://github.com/Smit-05',
  //     //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
  //     img: Apoorva
  //   },
  //   {
  //     Name: 'Adarsh',
  //     role: 'Design Team',
  //     img: adarsh
  //     //github: 'https://github.com/Smit-05',
  //     //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
  //   },
  //   {
  //     Name: 'Vaibhavi ',
  //     role: 'Content & Curation',
  //     //github: 'https://github.com/Smit-05',
  //     //linkedin: 'https://www.linkedin.com/in/smit-padaliya-aa03291b8/',
  //     img: vaibhavi
  //   }
  // ]
];

const JudgesInfo = [
  [
    // Array 1
    {
      Name: 'Rehan',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: prajwal
    },
    {
      Name: 'Moon',
      role: 'Manager',
      github: '',
      linkedin: '',
      img: prajwal
    },
    {
      Name: 'Ryah Garcia',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: prajwal
    }
  ],
  [
    // Array 2
    {
      Name: 'Lyanola',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: prajwal
    },
    {
      Name: 'Zoheb',
      role: 'Organizer',
      github: '',
      linkedin: '',
      img: prajwal
    }
  ]
];

const sponsorLogos = [
  [
    {src: ieeeBangaloreCompSoc, link: 'https://cs.ieeebangalore.org/', type: ''},
    {src: wolfram, link: 'https://www.wolfram.com/', type: ''},
    
    {src: axure, link: 'https://www.axure.com/', type: ''},
    {src: echo3D, link: 'https://www.echo3d.com/', type: ''},

    {
      src: bindu,
      link: 'https://www.sgcorporates.com/bindu-jeera.html#Home',
      type: 'food'
    },
    {src: chegu, link: 'https://chegufoods.com/', type: 'food'}
  ]
];

const platformPartner = [[{src: hack2Skill, link: 'https://hack2skill.com/'}]];

// const titlesponsorLogos = [
//   [
//     {src: devfolio, link: 'https://devfolio.co/'},
//     {src: polygon, link: 'https://polygon.technology/'}
//   ] //Array 1
//   // [{ src: taskade }, { src: Replit }, { src: qoom }], //Array 2
//   // [{ src: interviewC }, { src: pass }, { src: glimpse }], //Array 3
//   // [{ src: egg }, { src: ll }, { src: ACF }] //Array 4
// ];

// const goldsponsorLogos = [
//   // [  ], //Array 1
//   [
//     {src: filecoin, link: 'https://filecoin.io/'},
//     {src: gfg, link: 'https://www.geeksforgeeks.org/'},
//   ] //Array 2
//   // [{ src: interviewC }, { src: pass }, { src: glimpse }], //Array 3
//   // [{ src: egg }, { src: ll }, { src: ACF }] //Array 4
// ];

// const silversponsorLogos = [
//   [
//     // {src: echo3d, link: 'https://www.echo3d.co/'},
//     {src: wolfram, link: 'https://www.wolfram.com/'},
//     {src: interviewbuddy, link: 'https://interviewbuddy.in/'}
//   ], //Array 1
//   // [{src: alan, link: 'https://alan.app/'}]
//   // [{ src: taskade }, { src: Replit }, { src: qoom }], //Array 2
//   // [{ src: interviewC }, { src: pass }, { src: glimpse }], //Array 3
//   // [{ src: egg }, { src: ll }, { src: ACF }] //Array 4
// ];

// const bronzeponsorLogos = [
//   [
//     // {src: replit, link: 'https://replit.com/'},
//     // {src: certopus, link: 'https://certopus.com/'},
//     // {src: taskade, link: 'https://www.taskade.com/'}
//   ], //Array 1
//   [
//     // {src: koii, link: 'https://www.koii.network/'},
//     // {src: postman, link: 'https://postman.com/'}
//   ]
//   // [{ src: taskade }, { src: Replit }, { src: qoom }], //Array 2
//   // [{ src: interviewC }, { src: pass }, { src: glimpse }], //Array 3
//   // [{ src: egg }, { src: ll }, { src: ACF }] //Array 4
// ];

// const designlogo = [
//   [
//     // {src: digilogo, link: 'https://www.instagram.com/digicosmic_studio/'},
//     {src: streamyard, link: 'https://streamyard.com/'},
//     // {src: shutterbugs, link: 'https://www.instagram.com/shutterbugs_ddu/?hl=en'}
//   ]
// ];

const frequentlyAskedQuestions = [
  [
    [
      {
        label: 'What is a hackathon?',
        content:
          'A hackathon is a collaborative event where participants work within a set timeframe to create innovative software or hardware projects, fostering creativity and competition. The projects are presented at the end for recognition and awards.'
      },
      {
        label: 'I am a first time hacker. Can I participate?',
        content:
          'Certainly! A hackathon is a great opportunity for first-time hackers to learn new skills, take initiative, and contribute to societal advancement through innovative projects.'
      },
      {
        label: 'Who all can participate?',
        content:
          'Participation is open to all currently enrolled engineering students from any institute across India.'
      },
      {
        label: 'How many participants are allowed per team?',
        content:
          'Teams must consist of a minimum of 2 participants and a maximum of 4 participants.'
      },
      {
        label:
          'Will the organizers furnish essential needs like food, refreshments, and internet for both days of Hack4Soc 2.0?',
        content:
          "Absolutely! The Hack4Soc 2.0 organizers will manage provisions for breakfast, lunch, snacks, and dinner on Day 1, as well as breakfast on Day 2. High-speed Wi-Fi will be available for all participants. You're welcome to bring your own broadband/hotspot devices for added convenience."
      },
      {
        label: 'How are teams shortlisted?',
        content:
          'A proficient panel comprising seasoned mentors, professors, and industry professionals will meticulously review all idea submissions across various themes. The top ideas will be shortlisted, and these teams will be invited to the offline hackathon to develop their prototypes.'
      }
    ],
    [
      {
        label: "Who's organising Hack4Soc 2 0?",
        content:
          // 'All you need is to register yourself on devfolio and join discord, we will guide you through everything there.'
          'Hack4Soc 2.0 is orchestrated by the IEEE RVCE Computer Society, a dynamic organization at RV College of Engineering. This event exemplifies their commitment to fostering innovation and collaboration in the realm of technology.'
      },
      {
        label:
          'Is physical presence required at the venue for the hackathon, or can I attend online if the rest of my team is participating virtually?',
        content:
          "To participate, you and your team must be at RVCE, Bangalore, for the entire 24-hour hackathon on February 3rd and 4th, 2024. It's essential to stay within the college campus during the event."
      },
      {
        label: 'Is there a registration fee for the event?',
        content:
          'No, registering for the event is free of charge. However, teams shortlisted for the second round will be required to pay Rs. 1000 per team.'
      },
      {
        label: 'What should I bring for the hackathon?',
        content:
          'Ensure you have your valid university ID card, a signed parental consent form (to be provided), and a signed college authorization letter (for non-RVCE students). Remember to bring your laptops, chargers, and any necessary hardware for your hack. Personal toiletries are also recommended. Note that firearms, weapons, alcohol, or illegal drugs are not permitted on campus. If selected, you will receive a detailed code of conduct.'
      },

      {
        label: 'Question not found?',
        content: 'If you have questions, reach out to hack4soc@gmail.com.'
      }
    ]
  ]
];

export {
  TOP_SECTION,
  MIDDLE_SECTION,
  SOCIALS,
  FOOTER,
  NGOSection,
  Prizeinfo,
  sponsorLogos,
  platformPartner,
  TeamInfo,
  JudgesInfo,
  frequentlyAskedQuestions,
  schedule,
  calenderStartingDate,
  HACKATHON_TRACKS
};
